import React from 'react';
import styled from 'styled-components';
import Slider from 'react-slick';
import '../../node_modules/slick-carousel/slick/slick.css';

import Container from './Container';
import Title from './Title';

const sliderSettings = {
	slidesToShow: 1,
	dots: true,
	arrows: false,
	infinite: false,
	speed: 500,
	fade: true,
	cssEase: 'linear',
	adaptiveHeight: true,
	autoplay: true,
	autoplaySpeed: 8000,
	pauseOnHover: false,
}

const Hero = ({ image, imageMobile, slides, logo, centered, tall, white }) => {
	let img = image ? image.sizes ? image.sizes.large.source_url : image.source_url : '',
		imgMobile = imageMobile ? imageMobile.sizes ? imageMobile.sizes.large.source_url : imageMobile.source_url : null;

	return(
		<Outer
			image={img}
			imageMobile={imgMobile}
			centered={centered}
			tall={tall}
			white={white}
		>
			<Slider {...sliderSettings}>
				{ slides.map((item, i) => {
					return(
						<ContentContainer key={item.type+i}>
							{ item.type === 'Logo and text' && (
								<LogoAndTextWrapper>
									{ item.image && (
										<img
											src={item.image.sizes.large.source_url}
											alt={item.image.sizes.large.alt_text}
											className={'hero__logo'}
										/>
									)}

									{ item.text && (
										<p dangerouslySetInnerHTML={{ __html: item.text }} />
									)}
								</LogoAndTextWrapper>
							) }

							{item.type === 'Title' && item.title && (
								<ContentWrapper>
									<Title
										size={'titleLg'}
										white={!white}
										// css={titleStyles}
										as="p"
										dangerouslySetInnerHTML={{__html: item.title}}
									/>
								</ContentWrapper>
							)}

							{item.type === 'Quote' && item.quote && (
								<ContentWrapper>
									<Quote dangerouslySetInnerHTML={{__html: `&ldquo;${item.quote}&rdquo;` }} />
									<QuoteAuthor dangerouslySetInnerHTML={{__html: item.author}} />
								</ContentWrapper>
							)}
						</ContentContainer>
					);
				}) }
			</Slider>
		</Outer>
	);
}

const ContentContainer = styled(Container)`
	
`;

const ContentWrapper = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	height: 100%;
	margin: 0 auto;
`;

const LogoAndTextWrapper = styled(ContentWrapper)`
	p {
		max-width: 435px;
		font-size: 30px;
		line-height: 45px;
	}
`;

const Quote = styled.blockquote`
	padding: 0;
	border: 0;
	font-size: 20px;
	line-height: 1.5;
`;
const QuoteAuthor = styled.p`
	max-width: 450px;
	font-family: ${({ theme }) => theme.fontFamily.sansHeavy};
	font-size: 20px;
`;

const Outer = styled.div`
	text-align: ${({ centered }) => centered ? 'center' : 'left'};
	background-color: ${({ theme, white }) => white ? '#fff' : theme.color.blue};
	background-image: ${({ image }) => image ? `url(${image})` : 'none'};
	background-position: ${({ centered }) => centered ? 'center' : 'center right'};;
	background-size: ${({ centered }) => centered ? '100%' : 'auto 80%'};;
	background-repeat: no-repeat;
	color: ${({ theme, white }) => white ? theme.color.blue : theme.color.white};
	
	${Container} {
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: ${({ centered }) => centered ? 'center' : 'flex-start'};
		height: ${({ tall }) => tall ? '668px' : '500px'};
		
		>* {
			max-width: ${({ centered }) => centered ? '750px' : '550px'};
		}
	}
	
	p {
		margin-top: 22px;
	}
	
	.hero__logo {
		height: 160px;
		max-height: 160px;
		margin-bottom: 25px;
	}

	.slick-dots {
		position: absolute;
		width: 100%;
		bottom: 40px;
		display: flex!important;
		justify-content: center;

		li {
			opacity: 0.7;
			margin: 6px;

			button {
				width: 9px;
				height: 9px;
				padding: 0;
				border: 0;
				border-radius: 50%;
				background: #fff;
				font-size: 0;
			}

			&.slick-active {
				opacity: 1;

				button {
					background: ${({ theme }) => theme.color.brand};
				}
			}
		}
	}
	
	@media (max-width: 1199px) {
		background-size: ${({ centered }) => centered ? 'cover' : 'auto 60%'};
	}
	
	@media (max-width: 991px) {
		background-image: ${({ image, imageMobile, centered }) => imageMobile ? `url(${imageMobile})` : centered ? `url(${image})` : 'none'};
		background-position: ${({ centered }) => centered ? 'center' : 'top right'};
	}
	
	@media (max-width: 599px) {
		${Quote},
		${QuoteAuthor} {
			font-size: 16px;
		}

		${Container} {
			min-height: 540px;
			height: calc(100vh - 100px);
		}
		.hero__logo {
			height: 100px;
			margin-bottom: 25px;
		}
		
		${Container} {
			/* height: 340px; */
		
			>* {
				max-width: ${({ centered }) => centered ? '500px' : '550px'};
			}
		}
		
		${({ centered }) => centered ? `
			${Title} {
				font-size: 32px;
			}
		` : `
			${Container} {
				justify-content: flex-end;
				padding-bottom: 60px;
			}
		`}
	}
`;

export default Hero;