import styled from 'styled-components';

const Section = styled.section`
	padding: 100px 0;
	text-align: center;

	ul {
		text-align: left;
	}
	
	@media (max-width: 767px) {
		padding: 60px 0;
	}
`;

export default Section;