import React, { useEffect, useState } from 'react';
import { Link, useStaticQuery, graphql } from 'gatsby';
import styled from 'styled-components';
import { format } from 'date-fns';

import { useTweets } from '../utils/hooks';

import ArticleCards from './ArticleCards';
import Container from './Container';
import FadeEntry from './FadeEntry';
import Section from './Section';
import Title from './Title';
import Tweets from './Tweets';

const LatestNews = ({ title, posts }) => {

	if(posts && posts.length) {
		posts = posts.slice(0, 3);
	}

	let tweets = useTweets(2);

	return(
		<Outer>
			<Container>
				{ title ? (
					<FadeEntry
						className={'latest-news__title'}
					>
						<Title
							as={'h3'}
							sectionTitle
						>{ title }</Title>
					</FadeEntry>
				) : null }

				<Tweets
					tweets={tweets}
				/>

				{ posts && !!posts.length ? (
					<ArticleCards
						posts={posts}
					/>
				) : null }
			</Container>
		</Outer>
	);
}

const Outer = styled(Section)``;

export default LatestNews;
