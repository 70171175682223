import React, { Fragment } from 'react';
import { graphql } from 'gatsby';

import CallToAction from '../components/CallToAction';
import HeroSlider from '../components/HeroSlider';
import LatestNews from '../components/LatestNews';
import MetaData from '../components/MetaData';
import ServiceCards from '../components/ServiceCards';

const Home = ({ pageContext: { services, posts, tweets }, data }) => {

	if (!data.wordpressPage) return null;

	let {
		title,
		acf: {
			seo_title,
			seo_description,
			seo_images,
			home_hero: {
				image,
				image_mobile,
				slides
			},
			home_services_title,
			home_services,
			home_services_link_text,
			cta_title,
			cta_text,
			call_to_action_link_2,
			cta_link_text,
			news_section_title,
		}
	} = data.wordpressPage;

	return(
		<Fragment>
			<MetaData
				title={seo_title || title}
				desciption={seo_description}
				images={seo_images}
			/>

			<HeroSlider
				image={image}
				imageMobile={image_mobile}
				slides={slides}
				centered
				tall
			/>

			<ServiceCards
				title={home_services_title}
				allServices={services}
				services={home_services}
				linkText={home_services_link_text}
			/>

			<CallToAction
				title={cta_title}
				text={cta_text}
				link={call_to_action_link_2}
				linkText={cta_link_text}
			/>

			<LatestNews
				title={news_section_title}
				tweets={tweets}
				posts={posts}
			/>
		</Fragment>
	);
}

export const pageQuery = graphql`
	query {
		wordpressPage(slug: { eq: "home" }) {
			title
			acf {
				home_hero {
					image {
						source_url
						alt_text
					}
					image_mobile {
						source_url
						alt_text
					}
					slides {
						type
						image {
							sizes {
								large {
									source_url
								}
							}
						}
						text
						title
						quote
						author
					}
				}
				home_services_title
				home_services {
					wordpress_id
				}
				home_services_link_text
				cta_title
				cta_text
				call_to_action_link_2
				cta_link_text
				news_section_title
				seo_title
				seo_description
				seo_images {
					image {
						source_url
					}
				}
			}
		}
	}
`;

export default Home;