import React from 'react';
import styled from 'styled-components';

import { getLinkProps } from '../utils/validation';

import Button from './Button';
import Container from './Container';
import FadeEntry from './FadeEntry';
import Section from './Section';
import Title from './Title';

const CallToAction = ({ title, text, link, linkText }) => {

	const linkProps = getLinkProps(link);

	return(
		<Outer
			noTitle={!title && text}
		>
			<Container>
				{ title ? (
					<FadeEntry
						className={'cta__title'}
					>
						<Title
							as={'h3'}
						>{ title }</Title>
					</FadeEntry>
				) : null }

				<FadeEntry
					className={'cta__text'}
				>
					<div
						dangerouslySetInnerHTML={{ __html: text }}
						className={'user-content'}
					/>
				</FadeEntry>

				{ link ? (
					<FadeEntry
						className={'cta__button'}
					>
						<Button
							{...linkProps}
						>{ linkText }</Button>
					</FadeEntry>
				) : null }
			</Container>
		</Outer>
	);
}

const Outer = styled(Section)`
	background: ${({ theme }) => theme.color.grey};
	
	.cta__text {
		max-width: 580px;
		margin: 25px auto 0;

		+* {
			margin-top: 35px;
		}
		
		* {
			font-size: ${({ theme }) => theme.fontSize.bodyLg};
			font-family: ${({ theme, noTitle }) => noTitle ? theme.fontFamily.sansMedium : theme.fontFamily.sans};
		}
	}
`;

export default CallToAction;