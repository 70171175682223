import React, { useState, useLayoutEffect, useRef } from 'react';
import { Link } from 'gatsby';
import styled from 'styled-components';
import smoothScroll from 'smoothscroll';
import Headroom from 'react-headroom';

import Button from './Button';
import Container from './Container';
import FadeEntry from './FadeEntry';
import Section from './Section';
import Title from './Title';

const ServiceCards = ({ title, services, linkText, allServices, noBorders, linked, dontMatchServices }) => {

	let $cards = useRef(null);
	let [pinStart, setPinStart] = useState(null);

	useLayoutEffect(() => {
		if(typeof window === 'undefined' || pinStart) return;

		let { offsetHeight, offsetTop } = $cards.current;

		setPinStart(offsetHeight + offsetTop + 380);
	}, [$cards]);

	if(!services || !services.length) return null;

	if(!dontMatchServices) {
		services = services.map(({ wordpress_id }) => {
			let service = allServices.find(({ node }) => wordpress_id === node.wordpress_id);

			return service ? service.node : null;
		});
	}

	const scrollTo = (id) => {
		let el = document.getElementById(id);

		if(!el) return;

		smoothScroll(el);
	}

	return(
		<Headroom
			disable={!linked}
			pinStart={pinStart}
		>
			<Outer
				noPadBottom={linked}
				cards={services.length}
				noBorders={noBorders}
				ref={$cards}
			>
				<Container>
					{ title ? (
						<FadeEntry
							className={'service-cards__title'}
						>
							<Title
								size={'titleLg'}
								as={'h2'}
								sectionTitle
							>{ title }</Title>
						</FadeEntry>
					) : null }

					<Inner>
						{ services.map(({ wordpress_id, title, slug, acf }) => (
							<FadeEntry
								className={'service-cards__card'}
								key={wordpress_id}
							>
								{ linked ? (
									<button
										onClick={() => scrollTo(slug)}
										title={title}
									>
										<img
											src={acf.service_image.sizes.large.source_url}
											alt={title}
										/>

										<p>{ title }</p>
									</button>
								) : (
									<Link
										to={`/services#${slug}`}
										title={title}
									>
										<img
											src={acf.service_image.sizes.large.source_url}
											alt={title}
										/>

										<p>{ title }</p>
									</Link>
								) }
							</FadeEntry>
						)) }
					</Inner>

					{ linkText ? (
						<FadeEntry
							className={'service-cards__button'}
						>
							<Button
								as={Link}
								to={'/services'}
							>{ linkText }</Button>
						</FadeEntry>
					) : null }
				</Container>
			</Outer>
		</Headroom>
	);
}

const Inner = styled.div`
	display: flex;
	justify-content: center;
	flex-wrap: wrap;
	margin: 0 -20px;
`;

const Outer = styled(Section)`
	background: ${({ theme }) => theme.color.white};
	
	${({ noPadBottom }) => noPadBottom && `
		padding-bottom: 0;
	`}
	
	.headroom--scrolled & {
		padding-top: 100px;
		padding-bottom: 0;
		
		.service-cards__card {	
			width: calc(100% / 6);
			margin: 0;
			
			>* {
				position: relative;
				width: 100%;
				padding: 15px 15px 30px;
				margin: 0;
				
				&:after {
					content: '';
					position: absolute;
					top: 0;
					left: 0;
					width: 100%;
					height: 100%;
					background: rgba(255, 255, 255, 0.25);
					opacity: 0;
					transition: opacity .3s;
					z-index: 2;
				}
				
				&:hover:after {
					opacity: 1;
				}
			}
			
			p {
				display: none;
			}
			
			svg,
			img {
				height: 35px;
			}
		}
	}

	${Title} {
		text-align: center;
		margin-bottom: 43px;
	}
	
	.service-cards__card {
		display: flex;
		justify-content: center;
		align-items: stretch;
		width: 180px;
		margin: 8px;
		
		>* {
			display: flex;
			flex-direction: column;
			justify-content: flex-start;
			align-items: center;
			width: 100%;
			padding: 22px 15px 15px;
			border: 0;
			background: ${({ theme }) => theme.color.white};
			color: ${({ theme }) => theme.color.text};
			box-shadow: ${({ theme, noBorders }) => noBorders ? 'none' : theme.shadow.card};
			text-decoration: none;
			transition: .5s;
			
			&:focus {
				outline: 0;
			}
		}
		
		svg,
		img {
			height: 100px;
			margin: 0 auto;
		}
		
		p {
			width: 100%;
			margin-top: 20px;
			font-family: ${({ theme }) => theme.fontFamily.sansHeavy};
			font-size: ${({ theme }) => theme.fontSize.titleSm};
			text-align: center;
			line-height: 120%;
		}
		
		&:nth-of-type(n+2) {
			//margin-left: 17px;
		}	
	}
	
	.service-cards__button {
		margin-top: 60px;
		text-align: center;
	}
	
	@media (max-width: 1149px) {
		.service-cards__card {
			margin: 12px;
		}
		
		${Title} {
			margin-bottom: 38px;
		}
	}
	
	@media (max-width: 767px) {
		.service-cards__button {
			margin-top: 30px;
		}
		
		${({ noPadBottom }) => noPadBottom && `
			padding-bottom: 0;
		`}
	}
`;

export default ServiceCards;